<template lang="pug">
	main#pagina(:class="{'background': background}", ref="pagina")
		BannerGeral(:contents="bannerContent", v-if="!hasBanner && !$store.state.loading")
		component(:is="item.component", v-for="item, index in mappedContents", :key="`${index}-${item.type}`", :contents="item.contents", :specialType="item.special", v-if="!$store.state.loading")
</template>

<script>
import pagina from '@/mixins/pagina'

export default {
  name: 'view-pagina',
  mixins: [pagina],
  computed: {
    bannerContent () {
      return { content: { title: { pt: this.pagina.title } } }
    }
  },
  data () {
    return {
      background: false
    }
  },
  mounted () {
    if (this.$route.params.slug == 'galeria-de-presidentes') { this.background = true }
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      if (to.params.slug == 'galeria-de-presidentes') { vm.background = true } else { vm.background = false }
    })
  },
  beforeRouteUpdate (to, from, next) {
    if (to.params.slug == 'galeria-de-presidentes') { this.background = true } else { this.background = false }
    next()
  }
}
</script>

<style lang="stylus" scoped src="./Pagina.styl"></style>
